
import Vue from 'vue';
import Chart from 'chart.js';
import { currFirestore } from '@/util-functions/initialization-utils';
import { eInteractionMethods, eThemes } from '@/enums';
import { collection } from '@firebase/firestore';
import { deleteDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { t } from '@/util-functions/language-utils';
import { hideLoading, showLoading } from '@/util-functions/loading-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { showConfirmation } from '@/util-functions/notice-utils';
import { getUserDoc } from '@/util-functions/user-utils';

interface AnalyzableObject {
  id: string;
  url?: string;
  name?: string;
}

interface Analytics {
  docId: string;
  name: string;
  viewCount: number;
  dayOfWeekViewCounts: {
    [dayOfWeek: number]: number;
  };
  hourOfDayViewCounts: {
    [hourOfDay: number]: number;
  };
  monthOfYearViewCounts: {
    [monthOfYear: number]: number;
  };
  yearViewCounts: {
    [year: number]: number;
  };
  interactionAnalytics?: any;
}

interface ViewData {
  name: string;
  viewCount: number;
}

export default Vue.extend({
  data(): {
    analyticsHeaders: TableHeaders;
    uniqueViewsHeaders: TableHeaders;
    modeAnalytics: Record<string, Analytics>;
    analyticsArray: Analytics[];
    currClickAnalyticsArray: Analytics[];
    currAnalyticsObject: Analytics | null;
    showAnalyticsDialog: boolean;
    deviceViews: ViewData[];        
  } {
    const analyticsHeaders = [
      { text: t.name, value: 'name' },
      { text: t.viewCount, value: 'viewCount' },
      { text: t.actions, value: '', sortable: false, align: 'center' },
    ];
    const uniqueViewsHeaders = [
      { text: t.name, value: 'name' },
      { text: t.viewCount, value: 'viewCount' },
    ];
    return {
      analyticsHeaders,
      uniqueViewsHeaders,
      modeAnalytics: {},
      analyticsArray: [],
      currClickAnalyticsArray: [],
      currAnalyticsObject: null,
      showAnalyticsDialog: false,
      deviceViews: [],            
    };
  },
  computed: {
    hasAnalytics(): boolean {
      return Boolean(this.analyticsArray.length);
    },
    modes(): ModesMap {
      return this.$store.state.modes;
    },
  },
  mounted() {
    if (this.$store.state.appTheme === eThemes.dark) {
      Chart.defaults.global.defaultFontColor = '#fff';
    } else {
      Chart.defaults.global.defaultFontColor = '#000';
    }

    const uniqueViewsDoc = doc(currFirestore, getUserDoc().path, 'uniqueViews', 'uniqueViewsDoc');
    getDoc(uniqueViewsDoc)
      .then((uniqueViewsDocSnap) => {
        const deviceViews = [];                
        if (uniqueViewsDocSnap.exists()) {
          const uniqueViewsData = uniqueViewsDocSnap.data();
          for (const prop in uniqueViewsData.deviceTypes) {
            let deviceType = '';
            switch (Number(prop)) {
              case eInteractionMethods.inAppQr:
                deviceType = t.inAppQr;
                break;
              case eInteractionMethods.cardNfc:
                deviceType = t.cardNfc;
                break;
              case eInteractionMethods.cardQr:
                deviceType = t.cardQr;
                break;
              case eInteractionMethods.bandNfc:
                deviceType = t.bandNfc;
                break;
              case eInteractionMethods.dotNfc:
                deviceType = t.dotNfc;
                break;
              case eInteractionMethods.stickerNfc:
                deviceType = t.stickerNfc;
                break;
              case eInteractionMethods.link:
                deviceType = t.linkView;
                break;
              default:
                deviceType = t.unknownType;
            }
            deviceViews.push({
              name: deviceType,
              viewCount: uniqueViewsData.deviceTypes?.[prop] || 0,
            });
          }          
          this.deviceViews = deviceViews;                    
        }
      })
      .catch((error: any) => {
        showError(`Cannot get unique view data.`, error, true);
      });

    showLoading();

    getDocs(collection(currFirestore, 'users', this.$store.state.userId, 'analytics'))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.modeAnalytics[doc.id] = { ...(doc.data() as Analytics), docId: doc.id };
        });

        this.analyticsArray = Object.values(this.modeAnalytics) as Analytics[];

        const analytics: Analytics[] = this.analyticsArray.sort((a: Analytics, b: Analytics) => {
          return b.viewCount - a.viewCount;
        });

        if (!analytics.length) {
          return;
        }

        Vue.nextTick(() => {
          const paymentsChart: HTMLCanvasElement = this.$refs.countsChart as HTMLCanvasElement;
          new Chart(paymentsChart, {
            type: 'bar',
            data: {
              labels: analytics.map(({ name }) => name).slice(0, 10),
              datasets: [
                {
                  label: t?.highestViewCounts,
                  data: analytics.map(({ viewCount }) => viewCount).slice(0, 10),
                  backgroundColor: 'rgba(255, 139, 30, 0.2)',
                  borderColor: 'rgba(255, 139, 30, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            },
          });
        });
      })
      .catch((error: any) => {
        showError(`Could not get analytics data.`, error, true);
      })
      .finally(() => {
        hideLoading();
      });
  },
  methods: {
    resetAnalyticsFor(modeAnalytics: Analytics) {
      showConfirmation(t?.areYouSureYouWantToResetAnalytics, () => {
        // Reset anything by deleting the doc.
        deleteDoc(doc(currFirestore, 'users', this.$store.state.userId, 'analytics', modeAnalytics.docId));
        this.analyticsArray = this.analyticsArray.filter((ma) => ma.docId !== modeAnalytics.docId);
      });
    },
    getAnalyticsName(analyticsObject: AnalyzableObject) {
      if (analyticsObject.name) {
        return analyticsObject.name;
      } else if (analyticsObject.url) {
        return analyticsObject.url;
      } else if (analyticsObject.id) {
        return t[analyticsObject.id];
      }
    },
    hideModeAnalyticsDialog() {
      this.currAnalyticsObject = null;
      this.showAnalyticsDialog = false;
      this.currClickAnalyticsArray = [];
    },
    showAnalyticsDialogFunc(modeAnalytics: Analytics) {
      this.currAnalyticsObject = modeAnalytics;
      if (modeAnalytics.interactionAnalytics) {
        this.currClickAnalyticsArray = Object.values(modeAnalytics.interactionAnalytics) as Analytics[];
      } else {
        this.currClickAnalyticsArray = [];
      }

      const dayData: number[] = Array(7).fill(0);
      Object.entries(modeAnalytics.dayOfWeekViewCounts || {}).forEach(([day, count]) => {
        dayData[Number(day)] = count;
      });

      const hourData: number[] = Array(24).fill(0);
      Object.entries(modeAnalytics.hourOfDayViewCounts || {}).forEach(([hour, count]) => {
        hourData[Number(hour)] = count;
      });

      const monthData: number[] = Array(12).fill(0);
      Object.entries(modeAnalytics.monthOfYearViewCounts || {}).forEach(([month, count]) => {
        monthData[Number(month)] = count;
      });

      const dataSetOptions = {
        backgroundColor: 'rgba(255, 139, 30, 0.2)',
        borderColor: 'rgba(255, 139, 30, 1)',
        borderWidth: 1,
      };

      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };

      Vue.nextTick(() => {
        const byDayChart: HTMLCanvasElement = this.$refs.byHourChart as HTMLCanvasElement;
        new Chart(byDayChart, {
          type: 'bar',
          data: {
            labels: [t?.sunday, t?.monday, t?.tuesday, t?.wednesday, t?.thursday, t?.friday, t?.saturday],
            datasets: [
              {
                label: t?.dayViewCounts,
                data: dayData,
                ...dataSetOptions,
              },
            ],
          },
          options,
        });

        const byHourChart: HTMLCanvasElement = this.$refs.byDayChart as HTMLCanvasElement;
        new Chart(byHourChart, {
          type: 'bar',
          data: {
            labels: hourData.map((val, index) => {
              return index;
            }),
            datasets: [
              {
                label: t?.hourViewCounts,
                data: hourData,
                ...dataSetOptions,
              },
            ],
          },
          options,
        });

        const byMonthChart: HTMLCanvasElement = this.$refs.byMonthChart as HTMLCanvasElement;
        new Chart(byMonthChart, {
          type: 'bar',
          data: {
            labels: monthData.map((val, index) => {
              return index + 1;
            }),
            datasets: [
              {
                label: t?.monthViewCounts,
                data: monthData,
                ...dataSetOptions,
              },
            ],
          },
          options,
        });
      });

      this.showAnalyticsDialog = true;
    },
  },
});
