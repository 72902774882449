
import Vue from 'vue';
import { modeSubmissionsMixin } from '@/mixins';
import { google, outlook, office365, ics, CalendarEvent } from 'calendar-link';
import { eModeType } from '@/enums';
import { ExportToCsv } from 'export-to-csv';
import { endpoints } from '@/constants';
import { format } from 'date-fns';
import { deleteDoc, doc } from 'firebase/firestore';
import { currFirestore, standardApiFetch } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { getCustomFieldValue } from '@/util-functions/misc-utils';
import { showSuccess, showConfirmation } from '@/util-functions/notice-utils';
import { saveSettings, getUserModeGatewayDoc } from '@/util-functions/user-utils';

interface CalanderEvent {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
  bookingSlot: BookingSlot;
}

interface LocalData extends Partial<SubmissionPageData> {
  events: CalanderEvent[];
  selectedCalendarValue: string;
  weekday: number[];
  type: string;
  weekdays: Array<{ text: string; value: number[] }>;
  headers: TableHeaders;
  types: TextValue[];
}

export default Vue.extend({
  mixins: [modeSubmissionsMixin],
  data(): LocalData {
    const headers = [
      { text: t.slot, value: 'asStringWithDate' },
      { text: t.bookings, value: '' },
      { text: t.actions, value: '', sortable: false, align: 'center' },
    ];
    return {
      type: 'month',
      types: [
        {
          text: t.month,
          value: 'month',
        },
        {
          text: t.week,
          value: 'week',
        },
        {
          text: t.day,
          value: 'day',
        },
        {
          text: t.fourDay,
          value: '4day',
        },
      ],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: `${t.sunday} - ${t.saturday}`, value: [0, 1, 2, 3, 4, 5, 6] },
        { text: `${t.monday} - ${t.sunday}`, value: [1, 2, 3, 4, 5, 6, 0] },
        { text: `${t.monday} - ${t.friday}`, value: [1, 2, 3, 4, 5] },
      ],
      selectedCalendarValue: '',
      headers,
      events: [],
    };
  },
  computed: {
    showNewItemAlert: {
      get(): boolean {
        return this.$store.state.currUser.settings.showNewBookingAlert;
      },
      set(val: boolean) {
        saveSettings({ showNewBookingAlert: val });
      },
    },
    relevantModes(): BookingMode[] {
      return (Object.values(this.$store.state.modes) as AnyMode[]).filter((mode: AnyMode) => {
        return mode.type === eModeType.booking;
      }) as BookingMode[];
    },
  },
  watch: {
    submissionArray(bookingSlots: BookingSlot[]) {
      const events: CalanderEvent[] = [];
      bookingSlots.forEach((bookingSlot) => {
        events.push({
          name: '',
          start: new Date(bookingSlot.startDateInMilliseconds),
          end: new Date(bookingSlot.endDateInMilliseconds),
          color: '#c96200',
          timed: true,
          bookingSlot,
        });
      });

      this.events = events;
    },
  },
  methods: {
    getCustomFieldValue,
    formatDate(date: number): string {
      return date ? format(date, 'MMM dd yyyy h:mm a') : t.notApplicable;
    },
    getEventColor(event: CalanderEvent) {
      return event.color;
    },
    onShowEvent(event: any) {
      (this as any).showSubmissionDetailsDialogFunc(event.event.bookingSlot);
    },
    exportCsv() {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: t.exportCsvRecordsTitle.supplant([this.currSelectedMode?.name]),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(
        (this.submissionArray as BookingSlot[]).map((bookingSlot) => {
          const returnObj: any = {
            [t.slot]: bookingSlot.asStringWithDate,
            [t.bookings]: bookingSlot.bookerDetailsArray.length,
          };
          returnObj[t.bookers] =
            bookingSlot.bookerDetailsArray
              ?.map((bookerDetails) => {
                return `${bookerDetails.name} ${bookerDetails.email} ${bookerDetails.phoneNumber}`;
              })
              .join(',') || t.none;
          return returnObj;
        })
      );
    },
    getEventForCalLink(bookingSlot: BookingSlot): CalendarEvent {
      const eventForCalLink: CalendarEvent = {
        title: bookingSlot.organizationName,
        description: t.bookings,
        start: new Date(bookingSlot.startDateInMilliseconds),
        duration: [bookingSlot.durationMinutes, 'minute'],
        allDay: false,
        busy: false,
        guests: bookingSlot.bookerDetailsArray.map((details: BookerDetails) => details.email),
      };
      return eventForCalLink;
    },
    onGoogleClick(bookingSlot: BookingSlot) {
      window.open(google(this.getEventForCalLink(bookingSlot)), '_blank');
    },
    onOutlookClick(bookingSlot: BookingSlot) {
      window.open(outlook(this.getEventForCalLink(bookingSlot)), '_blank');
    },
    onOffice365Click(bookingSlot: BookingSlot) {
      window.open(office365(this.getEventForCalLink(bookingSlot)), '_blank');
    },
    onIcsClick(bookingSlot: BookingSlot) {
      window.open(ics(this.getEventForCalLink(bookingSlot)));
    },
    deleteCurrSubmission() {
      if (!(this.currSelectedMode && this.$data.currSubmission)) {
        return;
      }
      deleteDoc(doc(currFirestore, getUserModeGatewayDoc().path, 'modes', this.$data.currSelectedMode.docId, 'submissions', this.$data.currSubmission.docId))
        .then(() => {
          this.$data.submissionArray = this.$data.submissionArray.filter((submission: BookingSlot) => {
            return submission.docId !== this.$data.currSubmission.docId;
          });
          (this as any).hideSubmissionDetailsDialogFunc();
          showSuccess(t.cancellationSuccessful);
        })
        .catch(() => {
          showError(`Could not delete submission.`, null, true);
        });
    },
    cancelBooking(bookerDetails: BookerDetails) {
      const currSubmission = this.$data.currSubmission;
      showConfirmation(t?.confirmBookingDeletion, () => {
        standardApiFetch(endpoints.cancelBooking, {
          securityKey: bookerDetails.securityKey,
          userId: this.$store.state.userId,
          modeId: this.$data.currSelectedMode.docId,
          bookingDocId: currSubmission.docId,
          locale: navigator?.languages[0] || navigator.language || '',
          emailForReceivingNotifications: this.$data.currSelectedMode.emailForReceivingNotifications,
        })
          .then(() => {
            this.deleteCurrSubmission();
          })
          .catch(() => {
            showError(t.cancellationUnsuccessful);
          });
      });
    },
  },
});
