
import { endpoints } from '@/constants';
import { currFirestore, fbAuth, standardApiFetch } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { formatCurrency } from '@/util-functions/misc-utils';
import { format } from 'date-fns';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import Vue from 'vue';

interface AffiliatePayment {
  paymentIntentId: string;
  baseAmount: number;
  discountAmount: number;
  affiliateCut: number;
  currency: string;
  formattedDiscountAmount: string;
  formattedBaseAmount: string;
  formattedAffiliateCut: string;
  dateCreated: number;
  dateCreatedFormatted: string;
}

interface Affiliate {
  name: string;
  email: string;
  userId: string;
  promoCode: string;
  promoCodeDiscountPercentage: number;
  affiliateCutPercentage: number;
  dateCreated: Timestamp;
  dateUpdated: Timestamp;
}

export default Vue.extend({
  data(): {
    affiliatePayments: AffiliatePayment[];
    currAffiliate: Affiliate | null;
    headers: TableHeaders;
    totalAffiliateCut: {
      [currency: string]: number;
    };
  } {
    const headers: TableHeaders = [
      { text: t.date, value: 'dateCreated' },
      { text: t.base, value: 'baseAmount' },
      { text: t.discount, value: 'discountAmount' },
      { text: t.affiliateCut, value: 'affiliateCut' },
    ];
    return {
      affiliatePayments: [],
      currAffiliate: null,
      headers,
      totalAffiliateCut: {},
    };
  },
  mounted() {
    const currUser = fbAuth.currentUser;

    if (!currUser) {
      showError(`No logged in user`);
      return;
    }

    const affiliateDoc = doc(currFirestore, 'affiliates', this.$store.state.userId);
    getDoc(affiliateDoc).then((affiliateDoc) => {
      if (affiliateDoc.exists()) {
        this.currAffiliate = affiliateDoc.data() as Affiliate;
      }
    });

    currUser.getIdToken(true).then((idToken) => {
      standardApiFetch(endpoints.getPromoCodePayments, {
        idToken,
        userId: this.$store.state.userId,
      }).then((response) => {
        const affiliatePayments = response.successfulResponse?.affiliatePayments as AffiliatePayment[];
        this.affiliatePayments = affiliatePayments.map((payment) => {
          // Converting a firestore timestmap to a string and then back into an object messes it up. So rebuild it. This will likely happen everytime we send a firestore timestamp down from the node server.
          this.totalAffiliateCut[payment.currency] = (this.totalAffiliateCut[payment.currency] || 0) + payment.affiliateCut;
          return {
            ...payment,
            dateCreatedFormatted: format(payment.dateCreated, 'MMM dd yyyy, h:mm a'),
            formattedDiscountAmount: formatCurrency(payment.baseAmount, payment.currency, false, true),
            formattedBaseAmount: formatCurrency(payment.discountAmount, payment.currency, false, true),
            formattedAffiliateCut: formatCurrency(payment.affiliateCut, payment.currency, false, true),
          };
        });
      });
    });
  },
  methods: {
    formatCurrency,
  },
});
